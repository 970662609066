<template>
    <div class="Competing_A">
        <div class="rana_cons">
            <div class="rana_con_title">
                竞品分析
            </div>
            <div class="Com_A_box">
                <div class="_A_Box_Top">
                    <p class="_Top_P">填写竞品名称</p>
                    <div class="_top_input" v-for="(item,index) in form.jpmc" :key="index">
                        <el-input :placeholder="'填写竞品'+(index+1)+'名称'" size="small" v-model="item.name"></el-input>
                    </div>
                </div>
                <div class="_A_Box_Top">
                    <p class="_Top_P">分析维度（至少添加3个）</p>
                    <div class="_tag_div">
                        <el-tag
                            :key="tag"
                            :class="index==0 || index==1?'tag_none':''"
                            v-for="(tag,index) in form.fxwd"
                            closable
                            :disable-transitions="false"
                            @close="handleClose(tag)">
                            {{tag}}
                        </el-tag>
                        <el-input class="input-new-tag"
                            v-if="inputVisible"
                            v-model="inputValue"
                            ref="saveTagInput"
                            size="small"
                            @keyup.enter.native="handleInputConfirm"
                            @blur="handleInputConfirm"
                            maxlength="6"
                            >
                        </el-input>
                        <el-button v-else class="button-new-tag" size="small" v-preventReClick @click="showInput">
                            <i class="el-icon-plus"></i>
                        </el-button>
                    </div>
                    
                </div>
            </div>
            <!-- 按钮 -->
            <div class="Com_butt_div">
                <div class="Com_button_one" v-preventReClick @click="prevstep()">
                    <span>上一步</span>
                </div>
                <div class="Com_button_two" v-preventReClick @click="nextBotn()">
                    <span>下一步</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            input:'',
            inputVisible: false,
            inputValue: '',
            form:{
                jpmc:[],
                fxwd:[]
            }
        }
    },
    methods: {
        getapp(){
            this.axios.projectS5P1({
                token:this.$store.state.token,
                id:this.$store.state.cla_id,
            }).then(res=>{
                if(res.data.code==0){
                    var str = res.data.data.qrcode
                    this.$emit('info', str);
                    this.form = res.data.data.data
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(err=>{

            })
        },
        // 删除标签
        handleClose(tag) {
            this.form.fxwd.splice(this.form.fxwd.indexOf(tag), 1);
        },
        //新增标签
        showInput() {
            if(this.form.fxwd.length<15){
                this.inputVisible = true;
                this.$nextTick(_ => {
                    this.$refs.saveTagInput.$refs.input.focus();
                });
            }else{
                this.$message.error('最多可添加15个分析维度')
            }
            
        },
        //回车失去焦点
        handleInputConfirm() {
            const arr = this.form.fxwd.filter(el=>el==this.inputValue)
            if(arr.length>0){
                this.$message.error('不能和原有名称出现重复')
            }else{
                let inputValue = this.inputValue;
                if (inputValue) {
                    this.form.fxwd.push(inputValue);
                }
                this.inputVisible = false;
                this.inputValue = '';
            }
        },
        prevstep(){
            this.$router.push({name:'StudentPage'})
        },
        nextBotn(){
            this.axios.projectS5P1({
                token:this.$store.state.token,
                id:this.$store.state.cla_id,
            }).then(res=>{
                if(res.data.code==0){
                    var array = res.data.data.data
                    let arr = []
                    array.jpmc.filter((el,index)=>{
                        if(this.form.jpmc[index].name!=''){
                            if(el.name!=this.form.jpmc[index].name){
                                arr.push(this.form.jpmc[index].name)
                            }
                        }else{
                            arr = 1
                        }
                        
                    })
                    var arr1 = 1
                    var len1 = array.fxwd.length
                    var len2 = this.form.fxwd.length
                    if(len1===len2 && len1>=5 && len2>=5){
                        array.fxwd.filter((el,index)=>{
                            if(el==this.form.fxwd[index] ){
                                arr1 = 1
                            }else{
                                arr1 = 2
                            }
                        })
                    }else{
                        arr1 = 2
                    }
                    if(arr.length!=0 || arr1==2){
                        this.axios.Sets5({
                            token:this.$store.state.token,
                            id:this.$store.state.cla_id,
                            node:'p1',
                            value:this.form
                        }).then(res=>{
                            if(res.data.code==0){
                                this.$router.push({name:'Competing_B'})
                            }else{
                                this.$message.error(res.data.message)
                            }
                        }).catch(err=>{

                        })
                    }else{
                        this.$router.push({name:'Competing_B'})
                    }
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(err=>{

            })            
        }        
    },
    mounted() {
        this.getapp()
    }
}
</script>
<style scoped>
@import './../../../assets/css/student/A/Competing_A.css';
</style>
<style>
._top_input .el-input__inner{
    background: transparent;
    border-radius: 0;
    border: 1px solid #FFFFFF;
    padding: 0 10px;
    box-sizing: border-box;
    color: #fff;
    line-height: 32px;
}
._A_Box_Top .el-tag{
    background: transparent;
    border-radius: 0;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    color: #fff;
    line-height: 32px;
    margin: 0px 5px;
    margin-bottom: 10px;
}
._A_Box_Top .tag_none i{
    display: none;
}
._A_Box_Top .el-tag .el-tag__close{
    color: #fff;
}
._A_Box_Top .el-input__inner{
    background: transparent;
    border-radius: 0;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    color: #fff;
}
._A_Box_Top .el-button{
    background: transparent;
    border-radius: 0;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    color: #fff;
}
._A_Box_Top .el-tag .el-tag__close:hover {
    color: #FFF;
    background-color: #f56c6c;
}
._A_Box_Top .button-new-tag {
    margin: 0 5px;
    height: 32px;
    line-height: 32px;
    padding-top: 0;
    padding-bottom: 0;
    min-width: 90px;
    text-align: center;
    box-sizing: border-box;
}
._A_Box_Top .input-new-tag {
    width: 90px;
    margin: 0 5px;
    vertical-align: top;
}
</style>